import { Route, BrowserRouter } from "react-router-dom"
import Landing from "./layout/pages/Landing/Landing"
import LandingRouter from "./layout/LandingRouter"
import "./App.scss"
import AOS from "aos"
import "aos/dist/aos.css"
AOS.init()

const App = () => {
  return (
    <BrowserRouter>
      <>
        <Route exact path="/" component={Landing} />

        <Route exact path="/games" component={LandingRouter} />
        <Route exact path="/projects" component={LandingRouter} />
        <Route exact path="/art" component={LandingRouter} />
        <Route exact path="/about" component={LandingRouter} />
        <Route exact path="/contact" component={LandingRouter} />
      </>
    </BrowserRouter>
  )
}

export default App
