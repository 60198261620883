import GameRow from "../../components/GamesRow/GamesRow"
import "./Games.scss"

const Games = () => {
  return (
    <>
      <div className="fywText">Games</div>
      <div className="gamesContainer">
        <GameRow />
      </div>
    </>
  )
}

export default Games
