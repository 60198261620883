import "./Landing.scss"
import { Link } from "react-router-dom"

const Landing = () => {
  return (
    <div className="lp2_HomePage">
      <div className="HomePage">
        <div className="heroAnimation">
          <div id="heroLayer0" className="heroLayer"></div>
          <div id="heroLayer1" className="heroLayer"></div>
          <div id="heroLayer2" className="heroLayer"></div>
          <div id="heroLayer3" className="heroLayer"></div>
        </div>

        <div className="heroContent">
          <span className="logoImage"></span>
          <div className="heroP">A developer story.</div>
          <Link to="/about">
            <div className="selectBtnContainer">Enter</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Landing
