import { useState } from "react"
import "./Contact.scss"
import axios from "axios"
import LinearProgress from "@material-ui/core/LinearProgress"

const Contact = () => {
  const [firstName, setFirstName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [buttonState, setButtonState] = useState(false)
  const [sending, setSending] = useState(false)

  const handleSubmit = async (e) => {
    setSending(true)
    document.getElementById("theForm").reset()
    e.preventDefault()
    axios
      .post("https://api.onlysansone.ca/email", {
        name: firstName,
        email: email,
        message: message,
      })
      .then((res) => {
        setButtonState(true)
        setSending(false)
      })
  }

  const handleButtonSpot = () => {
    return buttonState ? (
      <div id="formSubmitText">
        {" "}
        Thank you for filling out the form.
        <br /> Your information has been successfully sent!
      </div>
    ) : (
      <button className="button">Submit</button>
    )
  }

  return (
    <>
      <div className="fywText">Contact</div>
      <div className="lp2_contactContainer">
        <div className="contactContent">
          <div className="contactHeader">
            <p data-aos="fade-down">Got a question?</p>
            <p>
              If you are interested in a custom art project or development
              project
              <br />
              lets discuss further opportunity
            </p>
          </div>

          <form
            id="theForm"
            className="contactForm"
            onSubmit={(e) => handleSubmit(e)}
          >
            <div className="input-container">
              <i className="icon icon1"></i>
              <input
                id="firstName"
                type="text"
                name="firstName"
                placeholder="Your name"
                required
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div className="input-container">
              <i className="icon icon2"></i>
              <input
                id="email"
                type="email"
                name="email"
                placeholder="Your email"
                required
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="input-container">
              <i className="icon icon3"></i>
              <textarea
                id="message"
                type="textarea"
                name="message"
                placeholder="Your Message"
                required
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="submitContainer">
              {sending ? <LinearProgress /> : handleButtonSpot()}
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Contact

/* EXAMPLE of spread operator and object state 
    for example purposes, can be deleted safely if comfortable with info
*/
// import { useState } from 'react';

// export default function Form() {
//   const [person, setPerson] = useState({
//     name: 'Niki de Saint Phalle',
//     artwork: {
//       title: 'Blue Nana',
//       city: 'Hamburg',
//       image: 'https://i.imgur.com/Sd1AgUOm.jpg',
//     }
//   });

//   function handleNameChange(e) {
//     setPerson({
//       ...person,
//       name: e.target.value
//     });
//   }

//   function handleTitleChange(e) {
//     setPerson({
//       ...person,
//       artwork: {
//         ...person.artwork,
//         title: e.target.value
//       }
//     });
//   }

//   function handleCityChange(e) {
//     setPerson({
//       ...person,
//       artwork: {
//         ...person.artwork,
//         city: e.target.value
//       }
//     });
//   }

//   function handleImageChange(e) {
//     setPerson({
//       ...person,
//       artwork: {
//         ...person.artwork,
//         image: e.target.value
//       }
//     });
//   }

//   return (
//     <>
//       <label>
//         Name:
//         <input
//           value={person.name}
//           onChange={handleNameChange}
//         />
//       </label>
//       <label>
//         Title:
//         <input
//           value={person.artwork.title}
//           onChange={handleTitleChange}
//         />
//       </label>
//       <label>
//         City:
//         <input
//           value={person.artwork.city}
//           onChange={handleCityChange}
//         />
//       </label>
//       <label>
//         Image:
//         <input
//           value={person.artwork.image}
//           onChange={handleImageChange}
//         />
//       </label>
//       <p>
//         <i>{person.artwork.title}</i>
//         {' by '}
//         {person.name}
//         <br />
//         (located in {person.artwork.city})
//       </p>
//       <img
//         src={person.artwork.image}
//         alt={person.artwork.title}
//       />
//     </>
//   );
// }
