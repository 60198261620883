const Section8 = () => (
  <div className="p_section">
    <div className="p_sectionLeft">
      <div className="divider"></div>
      <div className="title">Pokepunkz</div>
      <div className="paragraph">
        An NFT project based off the famous cryptopunks.
      </div>
      <a href="http://pokepunkz.io/" target="_blank" rel="noopener noreferrer">
        <div className="button">Visit</div>
      </a>
    </div>
    <div className="p_sectionRight p8"></div>
  </div>
)

export default Section8
