const Section5 = () => (
  <div className="p_section">
    <div className="p_sectionLeft">
      <div className="divider"></div>
      <div className="title">Insecticide</div>
      <div className="paragraph">
        A good example of my front end work done at Kenna. This one was
        completed in GatsbyJs. This site, along others, were generated through a
        template engine I made.
      </div>
      <a
        href="https://agro.basf.ca/insecticide/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="button">Visit</div>
      </a>
    </div>
    <div className="p_sectionRight p5"></div>
  </div>
)

export default Section5
