const Section2 = () => (
  <div className="p_section">
    <div className="p_sectionLeft">
      <div className="divider"></div>
      <div className="title">NF Terrestrials</div>
      <div className="paragraph">
        A cool NFT project with aliens available on opensea.io ! - definitley
        worth checking out
      </div>
      <a
        href="http://nfterrestrials.io/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="button b123">Visit</div>
      </a>
    </div>
    <div className="p_sectionRight p2"></div>
  </div>
)

export default Section2
