import React, { Component } from "react"
import { NavLink, withRouter } from "react-router-dom"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"
import logo from "../../files/images/logoLW.png"
import linkedInImage from "../../files/images/li.png"
import gitHubImage from "../../files/images/gh.png"
import "./NavBar.scss"

class NavBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
      showOverlay: false,
    }
  }

  callAddOverlay = () => {
    this.setState({ showOverlay: !this.state.showOverlay })
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  render() {
    return (
      <div className="lp2_navBar">
        <div className="navBar">
          <div className="topNavContainer">
            <NavLink to="/" className="navitem-home">
              <img src={logo} alt="logo" />
            </NavLink>
            <NavLink to="/games" className="navItem">
              Games
            </NavLink>
            <NavLink to="/projects" className="navItem">
              Dev Projects
            </NavLink>
            <NavLink to="/art" className="navItem">
              Art Gallery
            </NavLink>
            <NavLink to="/about" className="navItem">
              About
            </NavLink>
            <NavLink to="/contact" className="navItem">
              Contact
            </NavLink>
          </div>
          <div className="bottomNavContainer">
            <a
              href="https://ca.linkedin.com/in/michael-sansone"
              target="_blank"
              rel="noopener noreferrer"
              className="navitem-home"
            >
              <img src={linkedInImage} alt="logo" />
            </a>
            <a
              href="https://github.com/mikeint"
              target="_blank"
              rel="noopener noreferrer"
              className="navitem-home"
            >
              <img src={gitHubImage} alt="logo" />
            </a>
          </div>
        </div>

        <div className="navBarMobile">
          <NavLink to="/" className="logoMobile">
            <img src={logo} alt="logo" />
          </NavLink>
          <div
            className={
              this.state.menuOpen ? "open navHamburger" : "navHamburger"
            }
            onClick={this.callAddOverlay}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <SwipeableDrawer
          anchor="left"
          className="navBarMobileItem"
          open={this.state.showOverlay}
          onClose={() =>
            this.setState({
              showOverlay: false,
              menuOpen: !this.state.menuOpen,
            })
          }
          onOpen={() =>
            this.setState({ showOverlay: true, menuOpen: !this.state.menuOpen })
          }
        >
          <div className="side-menu">
            <div className="topNavContainer">
              <NavLink
                to="/games"
                className="navItem"
                onClick={this.callAddOverlay}
              >
                {" "}
                Games
              </NavLink>
              <NavLink
                to="/projects"
                className="navItem"
                onClick={this.callAddOverlay}
              >
                {" "}
                Dev Projects
              </NavLink>
              <NavLink
                to="/art"
                className="navItem"
                onClick={this.callAddOverlay}
              >
                {" "}
                Art Gallery
              </NavLink>
              <NavLink
                to="/about"
                className="navItem"
                onClick={this.callAddOverlay}
              >
                {" "}
                About
              </NavLink>
              <NavLink
                to="/contact"
                className="navItem"
                onClick={this.callAddOverlay}
              >
                {" "}
                Contact
              </NavLink>
            </div>
            <div className="bottomNavContainer">
              <a
                href="https://ca.linkedin.com/in/michael-sansone"
                target="_blank"
                rel="noopener noreferrer"
                className="navitem-home"
              >
                <img src={linkedInImage} alt="logo" />
              </a>
              <a
                href="https://github.com/mikeint?tab=repositories"
                target="_blank"
                rel="noopener noreferrer"
                className="navitem-home"
              >
                <img src={gitHubImage} alt="logo" />
              </a>
            </div>
          </div>
        </SwipeableDrawer>
      </div>
    )
  }
}

export default withRouter(NavBar)
