const Section4 = () => (
  <div className="p_section">
    <div className="p_sectionLeft">
      <div className="divider"></div>
      <div className="title">BOLD</div>
      <div className="paragraph">
        A project I worked on with another entrepreneur involving
        cryptocurrency. A list of master nodes are compiled into funds which pay
        out dividends to investors.
      </div>
      <a
        href="https://sansonem-bold.s3.ca-central-1.amazonaws.com/index.html#/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="button">Visit</div>
      </a>
    </div>
    <div className="p_sectionRight p4"></div>
  </div>
)

export default Section4
