import { Switch, Route } from "react-router-dom"
import NavBar from "./components/NavBar/NavBar"
import Games from "./pages/Games/Games"
import Projects from "./pages/DevProjects/Projects"
import Art from "./pages/Art/Art"
import About from "./pages/About/About"
import Contact from "./pages/Contact/Contact"

import { ThemeContext, themes } from "./components/Themes/ThemesContext"

const LandingRouter = () => {
  return (
    <ThemeContext.Provider value={themes.light}>
      <div className="lp2_container">
        <div className="lp2_menu">
          <NavBar />
        </div>
        <div className="lp2_body">
          <Switch>
            <Route exact path="/games" component={Games} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/art" component={Art} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </div>
      </div>
    </ThemeContext.Provider>
  )
}

export default LandingRouter
